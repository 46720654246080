import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import CommonContent from "../components/CommonContent";
import Main from "../components/Main";
import ContentRow from "../components/ContentRow";
import ContactRow from "../components/ContactRow";
import StyledContentHeader from "../components/ContentHeader/style";
import BGImg from "../components/BGImg";
import SEO from "../components/seo";
import BlogPosts from "../components/Blog";
import SVGIcon from "../components/SVGIcon";

const Blog = ({ data, pageContext }) => {
	return (
		<Layout pageContext={pageContext}>
			<Main className="page-container">
				<SEO
					title={pageContext.BLOG_META_TITLE}
					description={pageContext.BLOG_META_DESCRIPTION}
					lang={pageContext.lang}
					keywords={["vývoj aplikací"]}
				/>

				<ContentRow rowContentClass="full-size" className="menuTrigger">
					<BGImg
						hookData={data.blog_header}
						fadeIn={false}
						className="blog_header"
						style={{ height: "100vh" }}
					>
						<StyledContentHeader>
							<CommonContent
								headline={pageContext.BLOG_HEAD_HEADLINE}
								content={pageContext.BLOG_HEAD_SUBHEADLINE}
								wrapperClass="header-content"
								contentSize="big"
							/>
						</StyledContentHeader>
					</BGImg>
				</ContentRow>

				<ContentRow background="white" rowContentClass="full-size no-padding">
					<p style={{ textAlign: "center", padding: "20px 0" }}>
						{data.categories.edges.map(item => (
							<Link
								key={`${item.node.frontmatter.url}`}
								style={{ display: "inline-block", padding: "10px 30px" }}
								to={`/${pageContext.lang}/blog/${item.node.frontmatter.url}`}
							>
								<SVGIcon
									name="faTag"
									className="green"
									style={{ width: "18px", height: "18px" }}
								/>
								{item.node.frontmatter.title}
							</Link>
						))}
					</p>
					<BlogPosts
						posts={data.posts.edges}
						categories={data.categories.edges}
						pageContext={pageContext}
					/>
				</ContentRow>

				<ContactRow pageContext={pageContext} />
			</Main>
		</Layout>
	);
};

Blog.propTypes = {
	data: PropTypes.shape({
		posts: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
							description: PropTypes.string.isRequired,
							category: PropTypes.string.isRequired,
							url: PropTypes.string.isRequired,
							date: PropTypes.string.isRequired,
							author: PropTypes.string.isRequired,
							lang: PropTypes.string.isRequired,
							cover: PropTypes.object.isRequired
						}).isRequired
					}).isRequired
				}).isRequired
			)
		}).isRequired,
		categories: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
							categoryName: PropTypes.string.isRequired,
							url: PropTypes.string.isRequired
						}).isRequired
					}).isRequired
				}).isRequired
			)
		}).isRequired
	}),
	pageContext: PropTypes.object.isRequired // eslint-disable-line
};

Blog.defaultProps = {
	data: {
		categories: {
			edges: {
				node: {
					frontmatter: {
						title: "",
						categoryName: "",
						url: ""
					}
				}
			}
		}
	}
};

export default Blog;

export const query = graphql`
	query BlogQuery($lang: String!) {
		posts: allMdx(
			filter: {
				fields: { source: { eq: "posts" } }
				frontmatter: { lang: { eq: $lang } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					frontmatter {
						title
						description
						category
						url
						date
						author
						lang
						cover {
							childImageSharp {
								fluid(maxWidth: 800, maxHeight: 360) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
		categories: allMdx(
			filter: {
				fields: { source: { eq: "categories" } }
				frontmatter: { lang: { eq: $lang } }
			}
		) {
			edges {
				node {
					frontmatter {
						categoryName
						title
						url
					}
				}
			}
		}
		blog_header: file(relativePath: { eq: "education.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
